import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import DOD from '../components/dods';

// import Frame1 from '../_images/profiles/tassel-2.jpg';
// import Frame2 from '../_images/profiles/tassel-3.jpg';
import socialBanner from '../_images/profiles/twitch-social.jpg';

Modal.setAppElement('#___gatsby')

export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      width: 0,
      height: 0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logger = this.logger.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  
  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    var pageTitle = 'Titans of Twitch';
    var pageDesc = 'Studying the muscle fibers of elite female weightlifters, Cal State Fullerton researchers made a shocking discovery: women had a higher concentration of fast-twitch fibers — the secret to explosive power — than ever recorded for any elite athlete, man or woman, in any sport.';

    // console.log('width: ' + this.state.width );
    var windowHeight = {
      minHeight: this.state.height
    }
    return (
      <React.Fragment>
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="profile-landing-page" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

          <link href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap" rel="stylesheet"></link>
        </Helmet>
        <section className="landing-wrap twitch" style={windowHeight}>
          <div className="profile-text" style={windowHeight}>
            <div className="text-wrap">
              
              <div className="row">
                <h1>{pageTitle}</h1>
                <div className="col-12">

                <p>Studying the muscle fibers of elite female weightlifters, Cal State Fullerton researchers made a shocking discovery: women had a higher concentration of fast-twitch fibers — the secret to explosive power — than ever recorded for any elite athlete, man or woman, in any sport.</p>

                <p>Their finding shattered the myth that women are not biologically predisposed to excel at exercises requiring hyper strength and power.</p>

                <p>Using this breakthrough knowledge, faculty and student research teams at the university’s Center for Sport Performance are now exploring unique methods to optimize strength training for world-class weightlifters.</p>

                <p>With center researchers seeking to understand muscular differences among female athletes — a vastly understudied group — and diving down to the molecular level, Cal State Fullerton is leading the way in sport performance research and evidenced-based training for competitive athletes. </p>

                  <h2>Be the difference.</h2>
                  <p><strong>Help advance sport performance research at Cal State Fullerton today.</strong></p>
                  <ul className="actions">
                    <li><a href="https://give.fullerton.edu/80464" className="buttonBlue" onClick={this.logger}>Donate Now</a></li>
                    <li><button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button></li>
                  </ul>

                  <DOD layout="2" college="hhd" />

                </div>
              </div>
              
            </div>

            

          </div>
          {/* <img src={Frame1} className="frame animated fadeIn" id="frame1" alt="Female student in cap and gown holding diploma up" /> */}
          {/* <img src={Frame2} className="frame animated fadeIn" id="frame2" alt="Hispanic students in cap and gown during commencement" /> */}
         
        </section>

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="twitch" college="the College of Health and Human Development" />
          </div>
        </Modal>
        
      </Layout>
      </React.Fragment>
    );
  }
}
